/**
 * 儲存成JSON字串
 */
export function saveToString(key, object) {
  localStorage.setItem(key, JSON.stringify(object))
}

export function saveString(key, text) {
  localStorage.setItem(key, text)
}

/**
 * 取得JSON物件
 */
export function getObject(key) {
  const text = localStorage.getItem(key)
  return text ? JSON.parse(text) : null
}

/**
 * 取得字串
 */
export function getString(key) {
  return localStorage.getItem(key)
}

/**
 * 刪除Local Storage
 * @param key
 */
export function removeFromSession(key) {
  localStorage.removeItem(key)
}
