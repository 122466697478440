<template>
  <div class="left-container">
    <div class="logo">
      <img src="~@/assets/menu-logo.png" />
    </div>
    <div class="menu" v-if="adminInfo">
      <div class="avatar-item">
        <span class="avatar"><img src="~@/assets/icon-seed.png"/></span>
        <span>{{ adminInfo.email }}</span>
      </div>
      <div class="menu-item" v-for="item in menus" :key="item.title">
        <div class="menu-parent" v-bind:class="{ active: item === activeMenu }" @click="toggleItem(item)">
          <div class="icon">
            <img :src="require(`@/assets/${item.icon}`)" />
          </div>
          <div class="text">
            {{ item.title }}
          </div>
          <img class="arrow" src="@/assets/menu-arrow.png" />
        </div>
        <transition name="expand" v-if="item.children && item.children.length" v-on:enter="enter" v-on:leave="leave">
          <ul
            class="menu-list"
            v-if="item === activeMenu"
            :style="{
              // height: `${38 * item.children.length}px`,
              height: 'auto'
            }"
          >
            <button class="menu-children" v-for="child in item.children" :key="child.title" @click="toMenuPath(child)">
              {{ child.title }}
            </button>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMenus } from '@/models/menu'
import { pushRouter } from '@/utils/router-util'

export default {
  name: 'LeftMenu',
  props: {
    msg: String
  },
  data() {
    return {
      activeMenu: null,
      expandHeight: 0
    }
  },
  methods: {
    toggleItem(clickedItem) {
      if (!clickedItem) return

      this.expandHeight = 38 * clickedItem.children.length
      this.activeMenu = clickedItem === this.activeMenu ? null : clickedItem
    },
    beforeEnter: function(item) {
      item.style.height = '0'
      // item.style.display = 'block'
    },
    enter: function(item) {
      item.style.height = 'auto'
      // item.style.height = `${this.expandHeight}px`
    },
    leave: function(item) {
      item.style.height = '0'
    },
    afterLeave: function() {
      // item.style.display = 'none'
    },
    toMenuPath(item) {
      pushRouter(item.routePath)
    }
  },
  computed: {
    ...mapGetters(['adminInfo', 'hasPermission']),
    menus() {
      const list = getMenus()

      return list
        .filter(item => this.hasPermission(item.permission))
        .map(item => {
          return {
            ...item,
            // icon: require(item.icon),
            active: false
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
.left-container {
  font-size: 1rem;
  font-weight: bold;
  background-color: #4094cf;
  min-height: 100vh;
  flex: 0 0 15%;
  max-width: 15%;
  min-width: 200px;
}

.logo {
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-item {
  color: #fff;
  padding: 7px 0;
  display: flex;
  align-items: center;
  font-size: 13px;

  .avatar {
    min-width: 60px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 34px;
      height: 34px;
      border-radius: 50%;
    }
  }

  //
  //.avatar {
  //  width: 34px;
  //  height: 34px;
  //  margin: 0 10px;
  //  display: block;
  //  border-radius: 50%;
  //  overflow: hidden;
  //  position: relative;
  //  color: #b0b0b0;
  //  text-transform: capitalize;
  //  font-size: 1rem;
  //
  //  .image {
  //    position: absolute;
  //    background-size: cover;
  //    background-position: 50%;
  //    top: 0;
  //    right: 0;
  //    left: 0;
  //    bottom: 0;
  //  }
  //
  //  .empty {
  //    background: #eee;
  //    text-align: center;
  //    line-height: 34px;
  //    display: block;
  //    font-size: 1rem;
  //    color: #999;
  //    font-weight: bolder;
  //  }
  //}
}

.menu {
  color: #fff;
}

.menu-item {
  user-select: none;
}

.menu-list {
  margin-left: 50px;
  padding-left: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  //display: block;
  //transition: all 0.5s ease;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
    height: calc(100% - 20px);
    width: 2px;
    background-color: #fff;
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s ease;
}

.menu-parent {
  display: flex;
  color: #fff;
  align-items: center;
  padding: 7px 0;
  cursor: pointer;

  border-left: 4px solid transparent;
  background: #4094cf;

  .icon {
    min-width: 60px;
    min-height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
      width: 30px;
    }
  }

  .arrow {
    margin-right: 20px;
    transition: 0.5s ease;

    transform: rotateX(0deg);
  }

  &.active {
    border-left: 4px solid #fff;
    background: #0762a9;

    .arrow {
      transform: rotateX(180deg);
    }
  }

  .text {
    flex: 1;
  }
}

.menu-children {
  color: #fff;
  padding: 7px 15px 7px 0;
  text-align: left;
}
</style>
