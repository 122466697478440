/**
 * Response工具
 */

/**
 * Response是否成功
 */
export function isResponseSuccess(response) {
  return response && response.code === '0'
}

export function isRecordNotFoundResponse(response) {
  return response && response.code === '-81'
}

export function isResponseAuthFail(response) {
  return response && response.code && response.code === -1003
}

/**
 * 取得Response data
 */
export function getResponseData(response) {
  return isResponseSuccess(response) && response.data != null ? response.data : null
}

/**
 * Response代號是否為
 */
export function isResponseCodeEquals(response, code) {
  return response && response.code && response.code == code
}
