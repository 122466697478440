import CryptoJS from 'crypto-js'

const key =
  ']Y_Fy?4C[G~rG(WdYCR+a]5^y@8K*>`/esh\\4]hJ2`v`FSYC{\\P`p`kDM#/n&Kw8KG/vVcH$nKp#xEE9%W!q".gny&)p84(s)`hs\'#X5UB\\9r3@/M]gV*Y+fJ>d>+VF8'

export function getKey() {
  return `${key.substring(0, 6)}CloudService${key.substring(6)}`
}

/**
 * 加密
 */
export function encrypt(message) {
  if (!message) return null
  const data = CryptoJS.AES.encrypt(message, getKey())

  return data ? data.toString() : data
}

/**
 * 解密
 */
export function decrypt(message) {
  if (!message) return null

  const bytes = CryptoJS.AES.decrypt(message, getKey())
  return bytes.toString(CryptoJS.enc.Utf8)
}
