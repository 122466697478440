import {
  ADMIN_LIST,
  ADMIN_ROLE_LIST,
  BANNER_LIST,
  CONTENT_ABOUT_US,
  CONTENT_CONTACT_US,
  CONTENT_HEALTH_EDUCATION,
  CONTENT_PLATFORM_SHARE,
  CONTENT_PRIVACY,
  CONTENT_QUESTION_AND_ANSWER,
  CONTENT_SHOPPING_NOTICE,
  CONTENT_TRAVELER,
  MEMBER_NEWS_LIST,
  NEWS_CATEGORY,
  NEWS_LIST,
  ORDER_LIST,
  ORDER_PURCHASING_THRESHOLD,
  POINT_EXPIRATION,
  POINT_SCHEDULE_LIST,
  PRODUCT_CATEGORY,
  PRODUCT_LIST,
  PRODUCT_SERIES,
  PRODUCT_VISIONS,
  PROMOTION_LIST,
  STORE_LIST,
  USER_LIST,
  WELFARE_LIST,
  SALESPERSON_LIST
} from '@/consts/uri'
import { CONTENT_JAPANESE_CURRENCY_RATE, CONTENT_CART_INFO, POINT_SMS_EDIT } from '../consts/uri'

const menus = [
  {
    title: '會員管理',
    permission: 'user',
    icon: 'menu-user.png',
    children: [
      {
        title: '會員列表',
        routePath: USER_LIST
      }
    ]
  },
  {
    title: '訂單管理',
    permission: 'order',
    icon: 'menu-order.png',
    children: [
      {
        title: '訂單列表',
        routePath: ORDER_LIST
      },
      {
        title: '購買門檻設定',
        routePath: ORDER_PURCHASING_THRESHOLD
      }
    ]
  },
  {
    title: '商品管理',
    permission: 'product',
    icon: 'menu-product.png',
    children: [
      {
        title: '商品類別管理',
        routePath: PRODUCT_CATEGORY
      },
      {
        title: '商品系列管理',
        routePath: PRODUCT_SERIES
      },
      {
        title: '度數/老花/散光/角度管理',
        routePath: PRODUCT_VISIONS
      },
      {
        title: '商品列表',
        routePath: PRODUCT_LIST
      }
    ]
  },
  {
    title: '促銷方案管理',
    permission: 'promotion',
    icon: 'menu-promotion.png',
    children: [
      {
        title: '促銷商品列表',
        routePath: PROMOTION_LIST
      }
    ]
  },
  {
    title: '福利品管理',
    permission: 'welfare',
    icon: 'menu-welfare.png',
    children: [
      {
        title: '福利品列表',
        routePath: WELFARE_LIST
      }
    ]
  },
  {
    title: '點數管理',
    permission: 'point',
    icon: 'menu-point.png',
    children: [
      {
        title: '有效期限設定',
        routePath: POINT_EXPIRATION
      },
      {
        title: '發送點數列表',
        routePath: POINT_SCHEDULE_LIST
      },
      {
        title: '簡訊發送設定',
        routePath: POINT_SMS_EDIT
      },
    ]
  },
  {
    title: '門市管理',
    permission: 'store',
    icon: 'menu-store.png',
    children: [
      {
        title: '門市列表',
        routePath: STORE_LIST
      },
      {
        title: '業務員列表',
        routePath: SALESPERSON_LIST
      }
    ]
  },
  {
    title: 'App內容管理',
    permission: 'content',
    icon: 'menu-content.png',
    children: [
      {
        title: '隱私權條款',
        routePath: CONTENT_PRIVACY
      },
      {
        title: '訂購須知',
        routePath: CONTENT_SHOPPING_NOTICE
      },
      {
        title: '關於我們',
        routePath: CONTENT_ABOUT_US
      },
      {
        title: '聯絡我們',
        routePath: CONTENT_CONTACT_US
      },
      {
        title: '日本旅人誌列表',
        routePath: CONTENT_TRAVELER
      },
      {
        title: '衛教資訊列表',
        routePath: CONTENT_HEALTH_EDUCATION
      },
      {
        title: 'Q & A列表',
        routePath: CONTENT_QUESTION_AND_ANSWER
      },
      {
        title: '平台分享列表',
        routePath: CONTENT_PLATFORM_SHARE
      },
      {
        title: '日圓匯率',
        routePath: CONTENT_JAPANESE_CURRENCY_RATE
      },
      {
        title: '購物車資訊管理',
        routePath: CONTENT_CART_INFO
      }
    ]
  },
  {
    title: '首頁BN管理',
    permission: 'banner',
    icon: 'menu-banner.png',
    children: [
      {
        title: '首頁BN列表',
        routePath: BANNER_LIST
      }
    ]
  },
  {
    title: '最新消息管理',
    permission: 'news',
    icon: 'menu-news.png',
    children: [
      {
        title: '最新消息類別管理',
        routePath: NEWS_CATEGORY
      },
      {
        title: '最新消息列表',
        routePath: NEWS_LIST
      }
    ]
  },
  {
    title: '會員專屬優惠管理',
    permission: 'news.member',
    icon: 'menu-member-news.png',
    children: [
      {
        title: '會員專屬優惠列表',
        routePath: MEMBER_NEWS_LIST
      }
    ]
  },
  {
    title: '後台帳號管理',
    permission: 'admin',
    icon: 'menu-admin.png',
    children: [
      {
        title: '權限列表',
        routePath: ADMIN_ROLE_LIST
      },
      {
        title: '管理者列表',
        routePath: ADMIN_LIST
      }
    ]
  }
]

export function getMenus() {
  return menus
}
