import { isResponseSuccess } from '@/utils/response-util'

const DialogMixin = {
  methods: {
    async showError(text, confirmCallback = null) {
      if (!text) return

      const result = await this.$swal({
        icon: 'error',
        text,
        confirmButtonText: '確定'
      })

      if (!result) return

      if (result['isConfirmed']) {
        confirmCallback && (await confirmCallback())
        return
      }
    },
    showErrorByResponse(response) {
      if (isResponseSuccess(response)) return false

      this.$swal({
        icon: 'error',
        text: response && response.message ? response.message : '操作失敗',
        confirmButtonText: '確定'
      })

      return true
    },
    async showHtml(text, confirmCallback = null) {
      if (!text) return

      const result = await this.$swal({
        icon: 'info',
        html: text,
        confirmButtonText: '確定'
      })

      if (!result) return

      if (result['isConfirmed']) {
        confirmCallback && (await confirmCallback())
        return
      }
    },
    async showSuccess(text, confirmCallback = null) {
      if (!text) return

      const result = await this.$swal({
        icon: 'success',
        text,
        confirmButtonText: '確定'
      })

      if (!result) return

      if (result['isConfirmed']) {
        confirmCallback && (await confirmCallback())
        return
      }
    },
    async showConfirm(text, confirmCallback = null, cancelCallback = null) {
      if (!text) return

      const result = await this.$swal({
        icon: 'warning',
        text,
        confirmButtonText: '確定',
        showCancelButton: true,
        cancelButtonText: '取消'
      })

      if (!result) return

      if (result['isConfirmed']) {
        confirmCallback && (await confirmCallback())
        return
      }

      cancelCallback && (await cancelCallback())
    },
    async showResponse(response, successText = null) {
      if (this.showErrorByResponse(response)) return

      await this.showSuccess(successText)
    }
  }
}

export default DialogMixin
