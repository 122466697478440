/**
 * 網路管理
 */

import { isArrayNotEmpty } from '@/utils/array-util'
import { addQueryString, getAdminUrlForRest } from '@/utils/url-util'
import { removeEmpty } from '@/utils/object-util'
import { getAccessToken } from '@/utils/admin-util'

const jsonHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const defaultErrorJsonResponse = {
  code: '-2'
}

/**
 * 取得授權Header
 */
export function getAuthHeader(token = null) {
  const accessToken = token || getAccessToken()

  if (!accessToken) return {}

  return {
    Authorization: `Bearer ${accessToken}`
  }
}

/**
 * 錯誤處理
 */
const handleRedirect = response => {
  if (response && response.status) {
    // if (response.status === 401 && response.url && response.url.indexOf(API_ADMIN_LOGIN) === -1) {
    //   const state = getState()
    //   if (state && state.admin && state.admin.loggedIn) {
    //     dispatchMutation(ADMIN_LOGOUT)
    //     showErrorAlertByKey('permission_denied_redirect', async () => {
    //       await getAuthAsync('logout')
    //     })
    //   }
    //
    //   return {
    //     status: 'failure',
    //     code: AUTH_FAIL
    //   }
    // }
  }
  return null
}

function getHeadersFromOptions(options) {
  return options && options.headers ? options.headers : {}
}

/**
 * 取得Json response
 */
function getJsonResponse(response) {
  const redirectResponse = handleRedirect(response)
  if (redirectResponse) {
    return redirectResponse
  }

  if (!response || !response.ok) {
    return defaultErrorJsonResponse
  }

  return response.json()
}

async function query(url, params) {
  try {
    return await fetch(url, params)
  } catch (error) {
    console.log('fetch failed:', error)
    return null
  }
}

async function queryJsonResponse(url, params) {
  const response = await query(url, params)

  return getJsonResponse(response)
}

/**
 * 取得參數
 * @param params
 */
export function getParameters(params) {
  const result = {}

  if (!params) return result

  const keys = Object.keys(params)

  if (isArrayNotEmpty(keys)) {
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i]
      if (Object.prototype.hasOwnProperty.call(params, key) && params[key] != null && params[key] !== '') {
        result[key] = params[key]
      }
    }
  }

  return result
}

export async function get(uri, params, options = {}) {
  const headers = getHeadersFromOptions(options)

  return await queryJsonResponse(addQueryString(getAdminUrlForRest(uri), removeEmpty(params)), {
    ...options,
    method: 'GET',
    headers: {
      ...headers,
      ...jsonHeader
    }
  })
}

/**
 * Get blob response
 */
export async function getBlob(uri, params) {
  const response = await query(addQueryString(getAdminUrlForRest(uri), getParameters(params)))

  return response.blob()
}

export async function getWithToken(uri, params, options = {}) {
  const headers = getHeadersFromOptions(options)

  return await queryJsonResponse(addQueryString(getAdminUrlForRest(uri), removeEmpty(params)), {
    options: {
      ...options,
      token: undefined
    },
    method: 'GET',
    headers: {
      ...headers,
      ...getAuthHeader(options && options.token ? options.token : null),
      ...jsonHeader
    }
    // mode: 'cors'
  })
}

/**
 * Post Async
 * @param uri 接口位置
 * @param params 參數
 * @param options
 */
export async function postWithQuery(uri, params, options = {}) {
  const headers = getHeadersFromOptions(options)

  return await queryJsonResponse(addQueryString(getAdminUrlForRest(uri), params), {
    ...headers,
    ...options,
    method: 'POST'
  })
}

export async function postForm(uri, form, options = {}) {
  const headers = getHeadersFromOptions(options)

  return await queryJsonResponse(getAdminUrlForRest(uri), {
    ...options,
    method: 'POST',
    body: form,
    headers: {
      ...headers,
      ...getAuthHeader()
    }
  })
}

export async function deleteWithToken(uri, id, options = {}) {
  const headers = getHeadersFromOptions(options)

  return await queryJsonResponse(getAdminUrlForRest(`${uri}/${id}`), {
    options: {
      ...options,
      token: undefined
    },
    method: 'DELETE',
    headers: {
      ...headers,
      ...jsonHeader,
      ...getAuthHeader(options && options.token ? options.token : null)
    }
  })
}

/**
 * Post Auth Async with body
 * @param uri
 * @param body
 * @param options
 * @param headers
 */
export async function post(uri, body = {}, options = {}) {
  const headers = getHeadersFromOptions(options)

  return await queryJsonResponse(getAdminUrlForRest(uri), {
    ...options,
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      ...headers,
      ...jsonHeader
    },
    mode: 'cors'
  })
}

export async function postWithQueryAndToken(uri, params, body = {}, options = {}) {
  const headers = getHeadersFromOptions(options)

  return await queryJsonResponse(addQueryString(getAdminUrlForRest(uri), params), {
    options: {
      ...options,
      token: undefined
    },
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      ...headers,
      ...jsonHeader,
      ...getAuthHeader(options && options.token ? options.token : null)
    }
  })
}

export async function postWithToken(uri, body, options = {}) {
  const headers = getHeadersFromOptions(options)

  return await queryJsonResponse(getAdminUrlForRest(uri), {
    options: {
      ...options,
      token: undefined
    },
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      ...headers,
      ...jsonHeader,
      ...getAuthHeader(options && options.token ? options.token : null)
    }
  })
}

export async function getBlobWithToken(uri, params, options = {}) {
  const headers = getHeadersFromOptions(options)

  const response = await query(addQueryString(getAdminUrlForRest(uri), removeEmpty(params)), {
    options: {
      ...options,
      token: undefined
    },
    responseType: 'arraybuffer',
    method: 'GET',
    headers: {
      ...headers,
      ...getAuthHeader(options && options.token ? options.token : null)
    }
  })

  return response ? await response.blob() : null
}

export async function postResponseWithToken(uri, body, options = {}) {
  const headers = getHeadersFromOptions(options)

  const response = await query(getAdminUrlForRest(uri), {
    options: {
      ...options,
      token: undefined
    },
    method: 'POST',
    responseType: 'blob',
    body: JSON.stringify(body),
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      ...getAuthHeader(options && options.token ? options.token : null)
    }
  })

  return response
}
