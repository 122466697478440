/**
 * 權限工具
 */

import { getState } from '@/store'

/**
 * 取得Access Token
 */
export function getAccessToken() {
  const state = getState()
  return state.admin && state.admin.token ? state.admin.token : null
}
