export const bannerTypes = {
  index: 'index',
  new: 'new',
  promotion: 'promotion'
}

export function getBannerTypeName(bannerType) {
  if (!bannerType) return ''

  switch (bannerType) {
    case bannerTypes.index:
      return '首頁BN'
    case bannerTypes.new:
      return '最新產品BN'
    case bannerTypes.promotion:
      return '促銷BN'
    default:
      return ''
  }
}
