/**
 * 前端網址Base
 */

export const LOGIN = '/login'
export const FORGOT_PASSWORD = '/password/forgot'

export const USER_LIST = '/user/list'
export const USER_VISION = '/user/vision'
export const USER_POINT = '/user/point'
export const USER_PURCHASING_HISTORY = '/user/purchasing-history'

export const ORDER_LIST = '/order/list'
export const ORDER_DETAIL = '/order/detail'
export const ORDER_PURCHASING_THRESHOLD = '/order/purchasing-threshold'

export const BANNER_LIST = '/banner/list'
export const BANNER_EDIT = '/banner/edit'

export const PRODUCT_CATEGORY = '/product/category'
export const PRODUCT_SERIES = '/product/series'
export const PRODUCT_VISIONS = '/product/visions'
export const PRODUCT_LIST = '/product/list'
export const PRODUCT_EDIT = '/product/edit'
export const WELFARE_LIST = '/welfare/list'

export const STORE_LIST = '/store/list'
export const STORE_EDIT = '/store/edit'

export const SALESPERSON_LIST = '/salesperson/list'

export const NEWS_CATEGORY = '/news/category'
export const NEWS_LIST = '/news/list'
export const NEWS_EDIT = '/news/edit'

export const MEMBER_NEWS_LIST = '/news/member/list'

export const POINT_EXPIRATION = '/point/expiration'
export const POINT_SMS_EDIT = '/point/smsedit'

export const POINT_SCHEDULE_LIST = '/point-schedule/list'
export const POINT_SCHEDULE_EDIT = '/point-schedule/edit'


export const PROMOTION_LIST = '/promotion/list'
export const PROMOTION_EDIT = '/promotion/edit'

export const ADMIN_LIST = '/admin/list'
export const ADMIN_EDIT = '/admin/edit'

export const ADMIN_ROLE_LIST = '/admin-role/list'
export const ADMIN_ROLE_EDIT = '/admin-role/edit'

export const CONTENT_PRIVACY = '/content/privacy'
export const CONTENT_ABOUT_US = '/content/about-us'
export const CONTENT_CONTACT_US = '/content/contact-us'

export const CONTENT_JAPANESE_CURRENCY_RATE = '/content/japanese-currency-rate'
export const CONTENT_CART_INFO = '/content/cart-info'

export const CONTENT_SHOPPING_NOTICE = '/content/shopping-notice'
export const CONTENT_SHOPPING_NOTICE_EDIT = '/content/shopping-notice/edit'

export const CONTENT_TRAVELER = '/content/traveler'
export const CONTENT_TRAVELER_EDIT = '/content/traveler/edit'

export const CONTENT_JAPANESE_RATE = '/content/japanese-rate'
export const CONTENT_JAPANESE_RATE_EDIT = '/content/japanese-rate/edit'

export const CONTENT_HEALTH_EDUCATION = '/content/health-education'
export const CONTENT_HEALTH_EDUCATION_EDIT = '/content/health-education/edit'

export const CONTENT_QUESTION_AND_ANSWER = '/content/question-and-answer'
export const CONTENT_QUESTION_AND_ANSWER_EDIT = '/content/question-and-answer/edit'

export const CONTENT_PLATFORM_SHARE = '/content/platform-share'
export const CONTENT_PLATFORM_SHARE_EDIT = '/content/platform-share/edit'

export const CONTENT_POINT_USAGE_GUIDE = '/content/point-usage-guide'
export const CONTENT_POINT_USAGE_GUIDE_EDIT = '/content/point-usage-guide/edit'
