import Vue from 'vue'
import VueRouter from 'vue-router'
import * as Uri from '@/consts/uri'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: Uri.LOGIN },
  {
    path: Uri.LOGIN,
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: Uri.USER_LIST,
    name: 'UserList',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserList.vue')
  },
  {
    path: Uri.USER_VISION,
    name: 'UserVision',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserVision.vue')
  },
  {
    path: Uri.USER_POINT,
    name: 'UserPoint',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserPoint.vue')
  },
  {
    path: Uri.USER_PURCHASING_HISTORY,
    name: 'UserPurchasingHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserPurchasingHistory.vue')
  },
  {
    path: Uri.ORDER_LIST,
    name: 'OrderList',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderList.vue')
  },
  {
    path: Uri.ORDER_DETAIL,
    name: 'OrderDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderDetail.vue')
  },
  {
    path: Uri.ORDER_PURCHASING_THRESHOLD,
    name: 'OrderPurchasingThreshold',
    component: () => import(/* webpackChunkName: "about" */ '../views/PurchasingThreshold.vue')
  },
  {
    path: Uri.BANNER_LIST,
    name: 'BannerList',
    component: () => import(/* webpackChunkName: "about" */ '../views/BannerList.vue')
  },
  {
    path: Uri.BANNER_EDIT,
    name: 'BannerEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/BannerEdit.vue')
  },
  {
    path: Uri.PRODUCT_CATEGORY,
    name: 'ProductCategory',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductCategory.vue')
  },
  {
    path: Uri.PRODUCT_SERIES,
    name: 'ProductSeries',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductSeries.vue')
  },
  {
    path: Uri.PRODUCT_LIST,
    name: 'ProductList',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductList.vue')
  },
  {
    path: Uri.PRODUCT_VISIONS,
    name: 'VisionList',
    component: () => import(/* webpackChunkName: "about" */ '../views/VisionList.vue')
  },
  {
    path: Uri.PRODUCT_EDIT,
    name: 'ProductEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductEdit.vue')
  },
  {
    path: Uri.WELFARE_LIST,
    name: 'WelfareList',
    component: () => import(/* webpackChunkName: "about" */ '../views/WelfareList.vue')
  },
  {
    path: Uri.STORE_LIST,
    name: 'StoreList',
    component: () => import(/* webpackChunkName: "about" */ '../views/StoreList.vue')
  },
  {
    path: Uri.STORE_EDIT,
    name: 'StoreEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/StoreEdit.vue')
  },
  {
    path: Uri.SALESPERSON_LIST,
    name: 'SalespersonList',
    component: () => import(/* webpackChunkName: "about" */ '../views/SalespersonList.vue')
  },
  {
    path: Uri.NEWS_LIST,
    name: 'NewsList',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsList.vue')
  },
  {
    path: Uri.NEWS_CATEGORY,
    name: 'NewsCategory',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsCategory.vue')
  },
  {
    path: Uri.NEWS_EDIT,
    name: 'NewsEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsEdit.vue')
  },
  {
    path: Uri.POINT_EXPIRATION,
    name: 'PointExpiration',
    component: () => import(/* webpackChunkName: "about" */ '../views/PointExpiration.vue')
  },
  {
    path: Uri.MEMBER_NEWS_LIST,
    name: 'MemberNewsList',
    component: () => import(/* webpackChunkName: "about" */ '../views/MemberNewsList.vue')
  },
  {
    path: Uri.CONTENT_PRIVACY,
    name: 'ContentPrivacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentPrivacy.vue')
  },
  {
    path: Uri.CONTENT_SHOPPING_NOTICE,
    name: 'ContentShoppingNotice',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentShoppingNotice.vue')
  },
  {
    path: Uri.CONTENT_SHOPPING_NOTICE_EDIT,
    name: 'ShoppingNoticeEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/ShoppingNoticeEdit.vue')
  },
  {
    path: Uri.CONTENT_ABOUT_US,
    name: 'ContentAboutUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentAboutUs.vue')
  },
  {
    path: Uri.CONTENT_CONTACT_US,
    name: 'ContentContactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentContactUs.vue')
  },
  {
    path: Uri.CONTENT_TRAVELER,
    name: 'ContentTraveler',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentTraveler.vue')
  },
  {
    path: Uri.CONTENT_TRAVELER_EDIT,
    name: 'TravelerEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/TravelerEdit.vue')
  },
  {
    path: Uri.CONTENT_HEALTH_EDUCATION,
    name: 'ContentHealth',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentHealth.vue')
  },
  {
    path: Uri.CONTENT_HEALTH_EDUCATION_EDIT,
    name: 'HealthEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/HealthEdit.vue')
  },
  {
    path: Uri.CONTENT_QUESTION_AND_ANSWER,
    name: 'ContentQuestionAndAnswer',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentQuestionAndAnswer.vue')
  },
  {
    path: Uri.CONTENT_QUESTION_AND_ANSWER_EDIT,
    name: 'QuestionAndAnswerEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionAndAnswerEdit.vue')
  },
  {
    path: Uri.CONTENT_PLATFORM_SHARE,
    name: 'ContentShare',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentPlatformShare.vue')
  },
  {
    path: Uri.CONTENT_PLATFORM_SHARE_EDIT,
    name: 'PlatformShareEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/PlatformShareEdit.vue')
  },
  {
    path: Uri.CONTENT_JAPANESE_CURRENCY_RATE,
    name: 'ContentJapaneseCurrencyRate',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentJapaneseCurrencyRate.vue')
  },
  {
    path: Uri.CONTENT_CART_INFO,
    name: 'ContentCartInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentCartInfo.vue')
  },
  {
    path: Uri.PROMOTION_LIST,
    name: 'PromotionList',
    component: () => import(/* webpackChunkName: "about" */ '../views/PromotionList.vue')
  },
  {
    path: Uri.PROMOTION_EDIT,
    name: 'PromotionEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/PromotionEdit.vue')
  },
  {
    path: Uri.ADMIN_ROLE_LIST,
    name: 'AdminRoleList',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminRoleList.vue')
  },
  {
    path: Uri.ADMIN_ROLE_EDIT,
    name: 'AdminRoleEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminRoleEdit.vue')
  },
  {
    path: Uri.ADMIN_LIST,
    name: 'AdminList',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminList.vue')
  },
  {
    path: Uri.ADMIN_EDIT,
    name: 'AdminEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminEdit.vue')
  },
  {
    path: Uri.POINT_SCHEDULE_LIST,
    name: 'PointScheduleList',
    component: () => import(/* webpackChunkName: "about" */ '../views/PointScheduleList.vue')
  },
  {
    path: Uri.POINT_SCHEDULE_EDIT,
    name: 'PointScheduleEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/PointScheduleEdit.vue')
  },
  {
    path: Uri.POINT_SMS_EDIT,
    name: 'PointSmsEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/PointSmsEdit.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
