export const ADMIN_INIT = 'ADMIN/INIT'
/**
 * 儲存管理員
 */
export const ADMIN_SAVE = 'ADMIN/SAVE'
/**
 * 管理員登出
 */
export const ADMIN_LOGOUT = 'ADMIN/LOGOUT'
/**
 * 更新管理員資訊
 */
export const ADMIN_UPDATE_INFO = 'ADMIN/INFO/UPDATE'
/**
 * 目前權限
 */
export const ADMIN_UPDATE_CURRENT_PERMISSION = 'ADMIN/PERMISSION/CURRENT/UPDATE'
/**
 * 設定編輯的使用者
 */
export const USER_CURRENT_SET = 'USER/CURRENT/SET'
/**
 * 清除編輯的使用者
 */
export const USER_CURRENT_CLEAR = 'USER/CURRENT/CLEAR'
/**
 * 設定目前訂單
 */
export const ORDER_CURRENT_SET = 'ORDER/CURRENT/SET'
/**
 * 清除目前訂單
 */
export const ORDER_CURRENT_CLEAR = 'ORDER/CURRENT/CLEAR'

/**
 * 設定操作狀態
 */
export const OPERATION_LOAD = 'OPERATION/LOAD'
export const OPERATION_RESET = 'OPERATION/RESET'
export const OPERATION_SAVE = 'OPERATION/SAVE'

export const OPERATION_BANNER_SET = 'OPERATION/BANNER/SET'
export const OPERATION_USER_SET = 'OPERATION/USER/SET'
export const OPERATION_ORDER_SET = 'OPERATION/ORDER_/SET'
export const OPERATION_PRODUCT_SET = 'OPERATION/PRODUCT/SET'
export const OPERATION_PROMOTION_SET = 'OPERATION/PROMOTION/SET'
export const OPERATION_STORE_SET = 'OPERATION/STORE/SET'
export const OPERATION_WELFARE_SET = 'OPERATION/WELFARE/SET'
