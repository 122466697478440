/**
 * 初始狀態
 */
import { ADMIN_LOGOUT, ADMIN_SAVE, ADMIN_UPDATE_INFO } from '@/store/type'
import { isArrayEmpty } from '@/utils/array-util'
import { removeFromSession, saveString } from '@/utils/storage-util'
import { encrypt } from '@/utils/crypto-util'
import { LOGIN, USER_LIST } from '@/consts/uri'
import { pushRouter } from '@/utils/router-util'
import { ADMIN_INIT } from './type'

const initialState = {
  // 是否登入
  loggedIn: false,
  // 帳號資訊
  info: null,
  // 存取權杖
  token: null,
  // 權限
  permissions: null,
  /**
   * 權限
   */
  permissionSet: new Set()
}

export const getInitialState = () => {
  return { ...initialState }
}

function updatePermissionSet(permissionSet, permissions) {
  permissionSet.clear()

  if (isArrayEmpty(permissions)) return

  permissions.forEach(permission => {
    permissionSet.add(permission)
  })
}

export const module = {
  state: () => getInitialState(),
  mutations: {
    /**
     * 儲存管理員
     */
    [ADMIN_INIT](state, { token, info }) {
      const { permissionSet } = state
      updatePermissionSet(permissionSet, info.permissions)

      saveString('data', encrypt(JSON.stringify({ token })))

      state.token = token
      state.info = info
      state.permissions = info.permissions
      state.permissionSet = permissionSet
      state.loggedIn = true

      pushRouter(USER_LIST)
    },
    /**
     * 儲存管理員
     */
    [ADMIN_SAVE](state, { token, info }) {
      const { permissionSet } = state
      updatePermissionSet(permissionSet, info.permissions)

      saveString('data', encrypt(JSON.stringify({ token })))

      state.token = token
      state.info = info
      state.permissions = info.permissions
      state.permissionSet = permissionSet
      state.loggedIn = true

      pushRouter(USER_LIST)
    },
    /**
     * 更新管理員資訊
     */
    [ADMIN_UPDATE_INFO](state, { info }) {
      const { permissionSet } = state
      updatePermissionSet(permissionSet, info.permissions)

      state.info = info
      state.permissions = info.permissions
      state.permissionSet = permissionSet
    },
    /**
     * 登出
     */
    [ADMIN_LOGOUT](state) {
      removeFromSession('data')

      state.permissionSet = new Set()
      state.info = null
      state.token = null
      state.loggedIn = false

      pushRouter(LOGIN)
    }
  },
  actions: {},
  getters: {
    accessToken: state => {
      return state.token ? state.token : null
    },
    hasPermission: state => permission => {
      return state.permissionSet && state.permissionSet.has(permission)
    },
    adminInfo: state => {
      return state.info
    },
    adminId: state => {
      return state.info ? state.info.id : null
    },
    isLoggedIn: state => {
      return state.loggedIn
    },
    roleId: state => {
      return state.info ? state.info.role_id : null
    }
  }
}
