<template>
  <header class="header">
    <div class="flex-grow-1">
      <nav v-if="false">
        <ol class="nav-list">
          <li class="item black">Home</li>
          <font-awesome-icon class="arrow" :icon="['fas', 'chevron-right']" />
          <li class="item blue"><a href="#">Order</a></li>
        </ol>
      </nav>
    </div>
    <div class="dropdown" v-click-outside="hideOption">
      <button class="button" @click="toggleOption">
        <img class="avatar" src="~@/assets/icon-seed.png" />
        <span>{{ adminInfo.email }}</span>
        <font-awesome-icon class="arrow" :icon="['fas', 'caret-down']" />
      </button>
      <button class="option" v-if="showOption">
        <button class="option-item" @click="logout">
          <img src="~@/assets/icon-logout.png" />
          <span>登出</span>
        </button>
      </button>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ClickOutside from 'vue-click-outside'
import { ADMIN_LOGOUT, OPERATION_RESET } from '@/store/type'

export default {
  name: 'Header',
  props: {
    msg: String
  },
  data() {
    return {
      showOption: false
    }
  },
  computed: {
    ...mapGetters(['adminInfo'])
  },
  methods: {
    ...mapMutations([ADMIN_LOGOUT, OPERATION_RESET]),
    logout() {
      this[ADMIN_LOGOUT]()
      this[OPERATION_RESET]()
    },
    toggleOption() {
      this.showOption = !this.showOption
    },
    hideOption() {
      this.showOption = false
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  max-height: 60px;
}

.arrow {
  color: #b2b9c3;
  margin: 0 10px;
}

.nav-list {
  margin: 0;
  padding: 0;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
  min-height: 60px;

  .item {
    display: flex;

    &.black {
      a {
        color: #4a4a4a;
      }
    }

    &.blue {
      color: #4094cf;
    }
  }
}

.dropdown {
  .button {
    border: 1px solid #4094cf;
    border-radius: 50px;
    background: transparent;
    padding: 0.375rem 0.75rem;
  }

  .avatar {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .arrow {
    color: #000;
  }

  .option {
    position: absolute;
    z-index: 1000;
    min-width: 10rem;
    padding: 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    width: 100%;
    left: 0;
    top: 99%;

    &-item {
      width: 100%;
      padding: 10px 20px;
      font-weight: 400;
      color: #212529;
      text-align: left;

      &:hover {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa;
      }

      img {
        margin-right: 15px;
        width: 20px;
      }
    }
  }
}
</style>
