/**
 * 清除空欄位
 * @param item
 */
export function removeEmpty(item) {
  if (!item) return {}

  Object.keys(item).forEach(key => {
    if (item[key] == null || item[key] === '') {
      delete item[key]
    }
  })

  return item
}

export function objectHasKey(item, key) {
  if (!item) return false

  return Object.prototype.hasOwnProperty.call(item, key)
}
