import router from '../router'

/**
 * 取得路由實體
 * @returns {*}
 */
export function getRouter() {
  return router
}

/**
 * 取得目前Uri
 * @returns {*}
 */
export function getRouterPath() {
  return router ? router.pathname : null
}

/**
 * 取得目前Uri
 * @returns {*}
 */
export async function pushRouterByName(name, params) {
  if (!router) return

  await router.push({ name, params })
}

/**
 * 取得目前Uri
 * @returns {*}
 */
export async function pushRouter(path, params) {
  if (!router || router.currentRoute.path === path) return

  await router.push({ path, query: params })
}

/**
 * 置換目前Uri
 * @returns {*}
 */
export async function replaceRouter(uri) {
  if (!router) return

  await router.replace(uri)
}

/**
 * 返回上頁
 * @returns {*}
 */
export function backRouter() {
  if (!router) return

  router.go(-1)
}
