import { USER_CURRENT_CLEAR, USER_CURRENT_SET } from './type'

/**
 * 初始狀態
 */
const initialState = {
  // 目前編輯的使用者
  current: null
}

export const getInitialState = () => {
  return { ...initialState }
}

export const module = {
  state: () => getInitialState(),
  mutations: {
    [USER_CURRENT_SET](state, { user }) {
      state.current = user
    },
    [USER_CURRENT_CLEAR](state) {
      state.current = null
    }
  },
  actions: {},
  getters: {
    currentUser: state => {
      return state.current ? state.current : null
    },
    currentUsername: state => {
      return state.current ? state.current.username : null
    },
    currentUserId: state => {
      return state.current ? state.current.id : null
    }
  }
}
