import Vue from 'vue'
import Vuex from 'vuex'
import * as admin from '@/store/admin'
import * as user from '@/store/user'
import * as order from '@/store/order'
import * as operation from '@/store/operation'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    admin: admin.module,
    user: user.module,
    order: order.module,
    operation: operation.module,
  }
})

export function getStore() {
  return store
}

export function getState() {
  return getStore().state
}

export default store
