import QueryString from 'qs'
import { getRouterPath } from './router-util'
import * as Url from '../consts/uri'
import { isStartsWith } from './string-util'

/**
 * 取得後端絕對網址
 * @param uri
 */
export function getAdminUrlForRest(uri) {
  if (!uri || isStartsWith(uri, 'http')) return uri

  return `${process.env.VUE_APP_SERVER_URL}${isStartsWith(uri, '/') ? '' : '/'}${uri}`
}

/**
 * 增加參數
 */
export function addQueryString(uri, params) {
  if (!params) return uri

  return `${uri}${QueryString.stringify(params, { addQueryPrefix: true })}`
}

/**
 * 取得參數字串
 */
export function getQueryString(params, addQueryPrefix = false) {
  if (!params) return ''
  return QueryString.stringify(params, { addQueryPrefix })
}

/**
 * 取得參數物件
 */
export function getParameter(queryString) {
  return QueryString.parse(queryString, { ignoreQueryPrefix: true })
}

/**
 * 取得參數物件
 */
export function getParameterByRouter() {
  return getParameter(getRouterPath())
}

/**
 * 根據URI與參數物件 取得連結網址
 * @param uri
 * @param params
 * @returns {string}
 */
export function getLink(uri, params) {
  const prefix = ''

  if (!params) {
    return `${prefix}${uri}`
  }

  return `${prefix}${uri}?${getQueryString(params)}`
}

/**
 * 取得圖片網址
 */
export function getImageUrl(url) {
  if (!url || isStartsWith(url, 'http')) {
    return url
  }

  return `${process.env.VUE_APP_SERVER_URL}${isStartsWith(url, '/') ? '' : '/'}${url}`
}

/**
 * 將字串圖片uri置換成絕對網址
 */
export function replaceImageUrlToAbsolute(text) {
  if (!text || typeof text !== 'string') return text

  return text.replace(
    /\/static\//g,
    `${process.env.VUE_APP_SERVER_URL}/static/`
  )
}
