import { bannerTypes } from '@/consts/banner-type'
import {
  OPERATION_BANNER_SET,
  OPERATION_LOAD,
  OPERATION_ORDER_SET,
  OPERATION_PRODUCT_SET,
  OPERATION_PROMOTION_SET,
  OPERATION_RESET,
  OPERATION_SAVE,
  OPERATION_STORE_SET,
  OPERATION_USER_SET,
  OPERATION_WELFARE_SET
} from './type'
import { getObject, saveToString } from '@/utils/storage-util'
import { storageKeys } from '@/consts/storage-key'

/**
 * 初始狀態
 */
const defaultState = {
  banner: {
    tab: bannerTypes.index,
    page: 1
  },
  user: {
    page: 1,
    search: {
      key: null,
      value: null
    }
  },
  order: {
    page: 1,
    search: {
      key: null,
      value: null,
      startTime: null,
      endTime: null
    }
  },
  product: {
    page: 1,
    search: {
      categoryId: 0,
      name: null
    }
  },
  promotion: {
    page: 1,
    search: {
      productType: null,
      name: null
    }
  },
  store: {
    page: 1,
    search: {
      name: null,
      salespersonId: 0,
      areaId: 0,
      parentAreaId: 0
    }
  },
  welfare: {
    page: 1,
    search: {
      categoryId: 0,
      name: null
    }
  }
}

export const getInitialState = () => {
  return {
    banner: { ...defaultState.banner },
    user: { ...defaultState.user },
    order: { ...defaultState.order },
    product: { ...defaultState.product },
    welfare: { ...defaultState.welfare },
    promotion: { ...defaultState.promotion },
    store: { ...defaultState.store }
  }
}

export const saveOperation = state => {
  saveToString(storageKeys.operationState, state)
}

export const module = {
  state: () => getInitialState(),
  mutations: {
    [OPERATION_LOAD](state) {
      const operationStates = getObject(storageKeys.operationState) || getInitialState()

      state.banner = operationStates.banner || { ...defaultState.banner }
      state.user = operationStates.user || { ...defaultState.user }
      state.product = operationStates.product || { ...defaultState.product }
      state.promotion = operationStates.promotion || { ...defaultState.promotion }
      state.store = operationStates.store || { ...defaultState.store }
      state.order = operationStates.order || { ...defaultState.order }
      state.welfare = operationStates.welfare || { ...defaultState.welfare }
    },
    [OPERATION_RESET](state) {
      const operationStates = getInitialState()

      state.banner = operationStates.banner
      state.user = operationStates.user
      state.product = operationStates.product
      state.promotion = operationStates.promotion
      state.store = operationStates.store
      state.order = operationStates.order
      state.welfare = operationStates.welfare

      saveOperation(operationStates)
    },
    [OPERATION_SAVE](state) {
      saveOperation(state)
    },
    [OPERATION_BANNER_SET](state, { current }) {
      state.banner = current

      saveOperation(state)
    },
    [OPERATION_USER_SET](state, { current }) {
      state.user = current

      saveOperation(state)
    },
    [OPERATION_PRODUCT_SET](state, { current }) {
      state.product = current

      saveOperation(state)
    },
    [OPERATION_ORDER_SET](state, { current }) {
      state.order = current

      saveOperation(state)
    },
    [OPERATION_PROMOTION_SET](state, { current }) {
      state.promotion = current

      saveOperation(state)
    },
    [OPERATION_STORE_SET](state, { current }) {
      state.store = current

      saveOperation(state)
    },
    [OPERATION_WELFARE_SET](state, { current }) {
      state.welfare = current

      saveOperation(state)
    }
  },
  actions: {},
  getters: {
    currentBannerOperation: state => {
      return state.banner ? state.banner : null
    },
    currentUserOperation: state => {
      return state.user ? state.user : null
    },
    currentOrderOperation: state => {
      return state.order ? state.order : null
    },
    currentProductOperation: state => {
      return state.product ? state.product : null
    },
    currentPromotionOperation: state => {
      return state.promotion ? state.promotion : null
    },
    currentStoreOperation: state => {
      return state.store ? state.store : null
    },
    currentWelfareOperation: state => {
      return state.welfare ? state.welfare : null
    }
  }
}
