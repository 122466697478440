<template>
  <div id="app">
    <LeftMenu v-if="isLoggedIn" />
    <div class="flex-grow-1 content-container">
      <Header v-if="isLoggedIn"></Header>
      <router-view v-if="isRouterAlive" />
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu'
import { mapGetters, mapMutations } from 'vuex'
import Header from '@/components/Header'
import { ADMIN_SAVE, OPERATION_LOAD, OPERATION_SAVE } from '@/store/type'
import { getString } from './utils/storage-util'
import { decrypt } from './utils/crypto-util'
import { getWithToken } from './network/network'
import { LOGIN } from './consts/uri'
import DialogMixin from './mixins/dialog'

export default {
  name: 'App',
  components: {
    LeftMenu,
    Header
  },
  mixins: [DialogMixin],
  metaInfo: {
    title: '',
    titleTemplate: '台灣實瞳-%s'
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      show: false,
      showMenu: false,
      isRouterAlive: true
    }
  },
  async created() {
    this[OPERATION_LOAD]({})

    if (!this.isLoggedIn && !this.$router.currentRoute.path.startsWith(LOGIN)) {
      await this.$router.replace(LOGIN)
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    ...mapMutations([ADMIN_SAVE, OPERATION_LOAD, OPERATION_SAVE]),
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    },
    async loadAdmin() {
      const data = getString('data')

      if (!data) return null

      const text = decrypt(data)

      if (!text) return null

      const admin = JSON.parse(text)

      if (!admin || !admin.token) return null

      const { token } = admin

      const response = await getWithToken('/admin/admins/info', null, {
        token
      })

      if (this.showErrorByResponse(response)) {
        return false
      }

      this[ADMIN_SAVE]({ token, info: response.data })

      return true
    }
  }
}
</script>

<style lang="scss">
#app {
  display: flex;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.content-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>
