/**
 * 陣列工具
 */

/**
 * 根據範圍 產生整數陣列
 */
export function createArrayByRange(start, end) {
  const array = []
  for (let i = start; i <= end; i += 1) {
    array.push(i)
  }
  return array
}

/**
 * 陣列是否為空
 */
export function isArrayEmpty(array) {
  return !Array.isArray(array) || array.length === 0
}

/**
 * 陣列是否不為空
 */
export function isArrayNotEmpty(array) {
  return Array.isArray(array) && array.length > 0
}

/**
 * 取得陣列第一元素
 */
export function getArrayFirst(array) {
  return isArrayEmpty(array) ? null : array[0]
}

/**
 * 陣列長度是否少於
 */
export function isArrayLengthLessThan(array, length) {
  return !Array.isArray(array) || array.length < length
}

/**
 * 取得最後
 */
export function getLastFromArray(array) {
  return isArrayEmpty(array) ? null : array[array.length - 1]
}

/**
 * 取得限制數量的陣列
 */
function getForLimitCount(array, count) {
  return array.length > count ? array.slice(0, count) : array
}

/**
 * 取得限制數量的陣列
 * @param array
 * @param start 起始
 * @param count
 * @returns {*}
 */
export function getFromStart(array, start, count) {
  if (!Array.isArray(array)) return null

  return start + count >= array.length ? array.slice(start) : array.slice(start, start + count)
}

/**
 * 取得限制固定數量的陣列
 * @param array 陣列
 * @param count 固定數量
 */
export function getArrayForFixedCount(array, count) {
  if (!Array.isArray(array) || array.length < count) return null
  return getForLimitCount(array, count)
}

/**
 * 取得限制最大數量的陣列
 * @param array 陣列
 * @param count 最大數量
 */
export function getArrayForMaxCount(array, count) {
  if (isArrayEmpty(array)) return null
  return getForLimitCount(array, count)
}

/**
 * 將陣列照數量切割成包含多個陣列的陣列
 * @param array
 * @param count
 * @returns {*}
 */
export function sliceArray(array, count) {
  if (isArrayEmpty(array)) return null

  const length = array.length
  const arrayCount = Math.ceil(length / count)
  const result = []

  for (let i = 0; i < arrayCount; i += 1) {
    const start = i * count
    const end = (i + 1) * count
    result.push(array.slice(start, end >= length ? length : end))
  }
  return result
}
