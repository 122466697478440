/**
 * 陣列工具
 */

/**
 * 根據範圍 產生整數陣列
 */
export function isString(text) {
  return typeof text === 'string' || text instanceof String
}

export function isStartsWith(text, prefix) {
  return isString(text) && text.indexOf(prefix) === 0
}

export function numberToTextWithSymbol(value) {
  const hasValue = value != null

  if (!hasValue) return null

  const negative = value < 0

  const suffix = negative ? value.toString().substr(1) : value.toString()

  return `${negative ? '-' : '+'}${suffix}`
}

export function maskName(value) {
  if (!value || value.length < 2) return value

  return `${value.charAt(0)}O${value.length > 2 ? value.substring(2) : ''}`
}
