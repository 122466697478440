import { ORDER_CURRENT_CLEAR, ORDER_CURRENT_SET } from './type'

/**
 * 初始狀態
 */
const initialState = {
  current: null
}

export const getInitialState = () => {
  return { ...initialState }
}

export const module = {
  state: () => getInitialState(),
  mutations: {
    [ORDER_CURRENT_SET](state, { order }) {
      state.current = order
    },
    [ORDER_CURRENT_CLEAR](state) {
      state.current = null
    }
  },
  actions: {},
  getters: {
    currentOrder: state => {
      return state.current ? state.current : null
    }
  }
}
